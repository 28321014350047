// @ts-nocheck
import client from '@/lib/client-fetch'
import { useMutation } from '@tanstack/react-query'
import type {
  PutV1SurveysSurveyidQuestionShareofwalletUpdateSowoptionClubSelectedMutationRequest,
  PutV1SurveysSurveyidQuestionShareofwalletUpdateSowoptionClubSelectedMutationResponse,
  PutV1SurveysSurveyidQuestionShareofwalletUpdateSowoptionClubSelectedPathParams,
  PutV1SurveysSurveyidQuestionShareofwalletUpdateSowoptionClubSelectedHeaderParams,
  PutV1SurveysSurveyidQuestionShareofwalletUpdateSowoptionClubSelected401,
  PutV1SurveysSurveyidQuestionShareofwalletUpdateSowoptionClubSelected403,
} from '../models/PutV1SurveysSurveyidQuestionShareofwalletUpdateSowoptionClubSelected'
import type { UseMutationOptions } from '@tanstack/react-query'

type PutV1SurveysSurveyidQuestionShareofwalletUpdateSowoptionClubSelectedClient =
  typeof client<
    PutV1SurveysSurveyidQuestionShareofwalletUpdateSowoptionClubSelectedMutationResponse,
    | PutV1SurveysSurveyidQuestionShareofwalletUpdateSowoptionClubSelected401
    | PutV1SurveysSurveyidQuestionShareofwalletUpdateSowoptionClubSelected403,
    PutV1SurveysSurveyidQuestionShareofwalletUpdateSowoptionClubSelectedMutationRequest
  >
type PutV1SurveysSurveyidQuestionShareofwalletUpdateSowoptionClubSelected = {
  data: PutV1SurveysSurveyidQuestionShareofwalletUpdateSowoptionClubSelectedMutationResponse
  error:
    | PutV1SurveysSurveyidQuestionShareofwalletUpdateSowoptionClubSelected401
    | PutV1SurveysSurveyidQuestionShareofwalletUpdateSowoptionClubSelected403
  request: PutV1SurveysSurveyidQuestionShareofwalletUpdateSowoptionClubSelectedMutationRequest
  pathParams: PutV1SurveysSurveyidQuestionShareofwalletUpdateSowoptionClubSelectedPathParams
  queryParams: never
  headerParams: PutV1SurveysSurveyidQuestionShareofwalletUpdateSowoptionClubSelectedHeaderParams
  response: Awaited<
    ReturnType<PutV1SurveysSurveyidQuestionShareofwalletUpdateSowoptionClubSelectedClient>
  >
  client: {
    parameters: Partial<
      Parameters<PutV1SurveysSurveyidQuestionShareofwalletUpdateSowoptionClubSelectedClient>[0]
    >
    return: Awaited<
      ReturnType<PutV1SurveysSurveyidQuestionShareofwalletUpdateSowoptionClubSelectedClient>
    >
  }
}
/**
 * @link /v1/surveys/:surveyId/question/shareofwallet/update-sowoption-club-selected
 */
export function usePutV1SurveysSurveyidQuestionShareofwalletUpdateSowoptionClubSelected(
  options: {
    mutation?: UseMutationOptions<
      PutV1SurveysSurveyidQuestionShareofwalletUpdateSowoptionClubSelected['response'],
      PutV1SurveysSurveyidQuestionShareofwalletUpdateSowoptionClubSelected['error'],
      {
        surveyId: PutV1SurveysSurveyidQuestionShareofwalletUpdateSowoptionClubSelectedPathParams['surveyId']
        headers?: PutV1SurveysSurveyidQuestionShareofwalletUpdateSowoptionClubSelected['headerParams']
        data: PutV1SurveysSurveyidQuestionShareofwalletUpdateSowoptionClubSelected['request']
      }
    >
    client?: PutV1SurveysSurveyidQuestionShareofwalletUpdateSowoptionClubSelected['client']['parameters']
  } = {},
) {
  const { mutation: mutationOptions, client: clientOptions = {} } =
    options ?? {}
  return useMutation({
    mutationFn: async ({ surveyId, data, headers }) => {
      const res = await client<
        PutV1SurveysSurveyidQuestionShareofwalletUpdateSowoptionClubSelected['data'],
        PutV1SurveysSurveyidQuestionShareofwalletUpdateSowoptionClubSelected['error'],
        PutV1SurveysSurveyidQuestionShareofwalletUpdateSowoptionClubSelected['request']
      >({
        method: 'put',
        url: `/v1/surveys/${surveyId}/question/shareofwallet/update-sowoption-club-selected`,
        data,
        headers: {
          'Content-Type': 'application/*+json',
          ...headers,
          ...clientOptions.headers,
        },
        ...clientOptions,
      })
      return res
    },
    ...mutationOptions,
  })
}
