// @ts-nocheck
import client from '@/lib/client-fetch'
import { useMutation } from '@tanstack/react-query'
import type {
  PutV1SurveysSurveyidQuestionShareofwalletMutationRequest,
  PutV1SurveysSurveyidQuestionShareofwalletMutationResponse,
  PutV1SurveysSurveyidQuestionShareofwalletPathParams,
  PutV1SurveysSurveyidQuestionShareofwalletHeaderParams,
  PutV1SurveysSurveyidQuestionShareofwallet401,
  PutV1SurveysSurveyidQuestionShareofwallet403,
} from '../models/PutV1SurveysSurveyidQuestionShareofwallet'
import type { UseMutationOptions } from '@tanstack/react-query'

type PutV1SurveysSurveyidQuestionShareofwalletClient = typeof client<
  PutV1SurveysSurveyidQuestionShareofwalletMutationResponse,
  | PutV1SurveysSurveyidQuestionShareofwallet401
  | PutV1SurveysSurveyidQuestionShareofwallet403,
  PutV1SurveysSurveyidQuestionShareofwalletMutationRequest
>
type PutV1SurveysSurveyidQuestionShareofwallet = {
  data: PutV1SurveysSurveyidQuestionShareofwalletMutationResponse
  error:
    | PutV1SurveysSurveyidQuestionShareofwallet401
    | PutV1SurveysSurveyidQuestionShareofwallet403
  request: PutV1SurveysSurveyidQuestionShareofwalletMutationRequest
  pathParams: PutV1SurveysSurveyidQuestionShareofwalletPathParams
  queryParams: never
  headerParams: PutV1SurveysSurveyidQuestionShareofwalletHeaderParams
  response: Awaited<ReturnType<PutV1SurveysSurveyidQuestionShareofwalletClient>>
  client: {
    parameters: Partial<
      Parameters<PutV1SurveysSurveyidQuestionShareofwalletClient>[0]
    >
    return: Awaited<ReturnType<PutV1SurveysSurveyidQuestionShareofwalletClient>>
  }
}
/**
 * @link /v1/surveys/:surveyId/question/shareofwallet
 */
export function usePutV1SurveysSurveyidQuestionShareofwallet(
  options: {
    mutation?: UseMutationOptions<
      PutV1SurveysSurveyidQuestionShareofwallet['response'],
      PutV1SurveysSurveyidQuestionShareofwallet['error'],
      {
        surveyId: PutV1SurveysSurveyidQuestionShareofwalletPathParams['surveyId']
        headers?: PutV1SurveysSurveyidQuestionShareofwallet['headerParams']
        data: PutV1SurveysSurveyidQuestionShareofwallet['request']
      }
    >
    client?: PutV1SurveysSurveyidQuestionShareofwallet['client']['parameters']
  } = {},
) {
  const { mutation: mutationOptions, client: clientOptions = {} } =
    options ?? {}
  return useMutation({
    mutationFn: async ({ surveyId, data, headers }) => {
      const res = await client<
        PutV1SurveysSurveyidQuestionShareofwallet['data'],
        PutV1SurveysSurveyidQuestionShareofwallet['error'],
        PutV1SurveysSurveyidQuestionShareofwallet['request']
      >({
        method: 'put',
        url: `/v1/surveys/${surveyId}/question/shareofwallet`,
        data,
        headers: {
          'Content-Type': 'application/*+json',
          ...headers,
          ...clientOptions.headers,
        },
        ...clientOptions,
      })
      return res
    },
    ...mutationOptions,
  })
}
