import { env } from '@/env/client'
import ky from 'ky'
import type { Session } from 'next-auth'

export type ErrorType<ErrorData> = ErrorData

export type BodyType<BodyData> = BodyData & { headers?: any }

export interface Props {
  url: string
  method?: 'get' | 'post' | 'put' | 'delete' | 'patch'
  params?: Record<string, any>
  headers?: Record<string, any>
  next?: NextFetchRequestConfig | undefined
  data?: BodyType<unknown> | FormData | unknown
  signal?: AbortSignal
}

interface ExtendedProps extends Props {
  session: Session | null
  getAccountId: () => Promise<string | undefined>
  getSession: () => Promise<Session | null>
}

const baseURL = env.NEXT_PUBLIC_API_ENDPOINT

export async function makeRequest<TData>({
  getAccountId,
  headers,
  url,
  method = 'get',
  params,
  data,
  session,
  getSession,
}: ExtendedProps) {
  const urlWithoutLeadingSlash = url.replace(/^\/+/g, '')
  const combinedHeaders = new Headers(headers)
  combinedHeaders.set('Authorization', `Bearer ${session?.accessToken}`)

  // To upload files using fetch and FormData you must not set Content-Type header.
  const isFormData = data instanceof FormData

  // Plain text needs to be stringified before sending it to a mutation.
  // To avoid double stringification, we need to pass the data to ky using the "body" instead of "json".
  const isPlainText = combinedHeaders.get('Content-Type') === 'text/plain'
  const isUpdateInviteRole =
    urlWithoutLeadingSlash.startsWith('v1/team/invites/') && method === 'put'
  const useBody = isFormData || isPlainText || isUpdateInviteRole

  if (isFormData) {
    // Remove the headers from the fetch request.
    // See https://flaviocopes.com/fix-formdata-multipart-fetch/
    combinedHeaders.delete('Content-Type')
  } else {
    combinedHeaders.set('Content-Type', 'application/json')
  }

  const response = await ky<TData>(urlWithoutLeadingSlash, {
    method,
    timeout: false,
    prefixUrl: baseURL,
    searchParams: params,
    [useBody ? 'body' : 'json']: data,
    headers: combinedHeaders,
    hooks: {
      beforeRequest: [
        async (request) => {
          if (request.headers.get('X-CurrentAccount') == null) {
            const accountId = await getAccountId()
            if (accountId != null) {
              request.headers.set('X-CurrentAccount', accountId)
            }
          }
        },
      ],
      beforeRetry: [
        async ({ request, options, error, retryCount }) => {
          const session = await getSession()
          if (session != null) {
            request.headers.set(
              'Authorization',
              `Bearer ${session?.accessToken}`,
            )
          }
        },
      ],
      beforeError: [
        (error) => {
          const { response } = error
          if (response?.body) {
            console.log(`${response.body} (${response.status})`)
          }

          return error
        },
      ],
      afterResponse: [
        // Or retry with a fresh token on a 403 error
        async (request, options, response) => {
          if (response.status === 401) {
            // Get a fresh token
            const session = await getSession()
            request.headers.set(
              'Authorization',
              `Bearer ${session?.accessToken}`,
            )

            return ky(request)
          }
        },
      ],
    },
  })

  const contentType = response.headers.get('content-type')

  if (contentType && contentType.includes('application/json')) {
    return response.json()
  } else {
    return response.text() as TData
  }
}
