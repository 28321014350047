// @ts-nocheck
import client from '@/lib/client-fetch'
import { useQuery, queryOptions, useSuspenseQuery } from '@tanstack/react-query'
import type {
  PostV1SurveysSurveyidQuestionShareofwalletGetPredefinedlistItemsByRadiusMutationRequest,
  PostV1SurveysSurveyidQuestionShareofwalletGetPredefinedlistItemsByRadiusMutationResponse,
  PostV1SurveysSurveyidQuestionShareofwalletGetPredefinedlistItemsByRadiusPathParams,
  PostV1SurveysSurveyidQuestionShareofwalletGetPredefinedlistItemsByRadiusHeaderParams,
  PostV1SurveysSurveyidQuestionShareofwalletGetPredefinedlistItemsByRadius401,
  PostV1SurveysSurveyidQuestionShareofwalletGetPredefinedlistItemsByRadius403,
} from '../models/PostV1SurveysSurveyidQuestionShareofwalletGetPredefinedlistItemsByRadius'
import type {
  QueryObserverOptions,
  UseQueryResult,
  QueryKey,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query'

type PostV1SurveysSurveyidQuestionShareofwalletGetPredefinedlistItemsByRadiusClient =
  typeof client<
    PostV1SurveysSurveyidQuestionShareofwalletGetPredefinedlistItemsByRadiusMutationResponse,
    | PostV1SurveysSurveyidQuestionShareofwalletGetPredefinedlistItemsByRadius401
    | PostV1SurveysSurveyidQuestionShareofwalletGetPredefinedlistItemsByRadius403,
    PostV1SurveysSurveyidQuestionShareofwalletGetPredefinedlistItemsByRadiusMutationRequest
  >
type PostV1SurveysSurveyidQuestionShareofwalletGetPredefinedlistItemsByRadius =
  {
    data: PostV1SurveysSurveyidQuestionShareofwalletGetPredefinedlistItemsByRadiusMutationResponse
    error:
      | PostV1SurveysSurveyidQuestionShareofwalletGetPredefinedlistItemsByRadius401
      | PostV1SurveysSurveyidQuestionShareofwalletGetPredefinedlistItemsByRadius403
    request: PostV1SurveysSurveyidQuestionShareofwalletGetPredefinedlistItemsByRadiusMutationRequest
    pathParams: PostV1SurveysSurveyidQuestionShareofwalletGetPredefinedlistItemsByRadiusPathParams
    queryParams: never
    headerParams: PostV1SurveysSurveyidQuestionShareofwalletGetPredefinedlistItemsByRadiusHeaderParams
    response: Awaited<
      ReturnType<PostV1SurveysSurveyidQuestionShareofwalletGetPredefinedlistItemsByRadiusClient>
    >
    client: {
      parameters: Partial<
        Parameters<PostV1SurveysSurveyidQuestionShareofwalletGetPredefinedlistItemsByRadiusClient>[0]
      >
      return: Awaited<
        ReturnType<PostV1SurveysSurveyidQuestionShareofwalletGetPredefinedlistItemsByRadiusClient>
      >
    }
  }
export const postV1SurveysSurveyidQuestionShareofwalletGetPredefinedlistItemsByRadiusQueryKey =
  (
    surveyId: PostV1SurveysSurveyidQuestionShareofwalletGetPredefinedlistItemsByRadiusPathParams['surveyId'],
    data: PostV1SurveysSurveyidQuestionShareofwalletGetPredefinedlistItemsByRadius['request'],
  ) =>
    [
      {
        url: '/v1/surveys/:surveyId/question/shareofwallet/get-predefinedlist-items-by-radius',
        params: { surveyId: surveyId },
      },
      ...(data ? [data] : []),
    ] as const
export type PostV1SurveysSurveyidQuestionShareofwalletGetPredefinedlistItemsByRadiusQueryKey =
  ReturnType<
    typeof postV1SurveysSurveyidQuestionShareofwalletGetPredefinedlistItemsByRadiusQueryKey
  >
export function postV1SurveysSurveyidQuestionShareofwalletGetPredefinedlistItemsByRadiusQueryOptions(
  surveyId: PostV1SurveysSurveyidQuestionShareofwalletGetPredefinedlistItemsByRadiusPathParams['surveyId'],
  data: PostV1SurveysSurveyidQuestionShareofwalletGetPredefinedlistItemsByRadius['request'],
  headers?: PostV1SurveysSurveyidQuestionShareofwalletGetPredefinedlistItemsByRadius['headerParams'],
  options: PostV1SurveysSurveyidQuestionShareofwalletGetPredefinedlistItemsByRadius['client']['parameters'] = {},
) {
  const queryKey =
    postV1SurveysSurveyidQuestionShareofwalletGetPredefinedlistItemsByRadiusQueryKey(
      surveyId,
      data,
    )
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        PostV1SurveysSurveyidQuestionShareofwalletGetPredefinedlistItemsByRadius['data'],
        PostV1SurveysSurveyidQuestionShareofwalletGetPredefinedlistItemsByRadius['error']
      >({
        method: 'post',
        url: `/v1/surveys/${surveyId}/question/shareofwallet/get-predefinedlist-items-by-radius`,
        data,
        headers: {
          'Content-Type': 'application/*+json',
          ...headers,
          ...options.headers,
        },
        ...options,
      })
      return res
    },
  })
}
/**
 * @link /v1/surveys/:surveyId/question/shareofwallet/get-predefinedlist-items-by-radius
 */
export function usePostV1SurveysSurveyidQuestionShareofwalletGetPredefinedlistItemsByRadius<
  TData = PostV1SurveysSurveyidQuestionShareofwalletGetPredefinedlistItemsByRadius['response'],
  TQueryData = PostV1SurveysSurveyidQuestionShareofwalletGetPredefinedlistItemsByRadius['response'],
  TQueryKey extends
    QueryKey = PostV1SurveysSurveyidQuestionShareofwalletGetPredefinedlistItemsByRadiusQueryKey,
>(
  surveyId: PostV1SurveysSurveyidQuestionShareofwalletGetPredefinedlistItemsByRadiusPathParams['surveyId'],
  data: PostV1SurveysSurveyidQuestionShareofwalletGetPredefinedlistItemsByRadius['request'],
  headers?: PostV1SurveysSurveyidQuestionShareofwalletGetPredefinedlistItemsByRadius['headerParams'],
  options: {
    query?: Partial<
      QueryObserverOptions<
        PostV1SurveysSurveyidQuestionShareofwalletGetPredefinedlistItemsByRadius['response'],
        PostV1SurveysSurveyidQuestionShareofwalletGetPredefinedlistItemsByRadius['error'],
        TData,
        TQueryData,
        TQueryKey
      >
    >
    client?: PostV1SurveysSurveyidQuestionShareofwalletGetPredefinedlistItemsByRadius['client']['parameters']
  } = {},
): UseQueryResult<
  TData,
  PostV1SurveysSurveyidQuestionShareofwalletGetPredefinedlistItemsByRadius['error']
> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey =
    queryOptions?.queryKey ??
    postV1SurveysSurveyidQuestionShareofwalletGetPredefinedlistItemsByRadiusQueryKey(
      surveyId,
      data,
    )
  const query = useQuery({
    ...(postV1SurveysSurveyidQuestionShareofwalletGetPredefinedlistItemsByRadiusQueryOptions(
      surveyId,
      data,
      headers,
      clientOptions,
    ) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<
    TData,
    PostV1SurveysSurveyidQuestionShareofwalletGetPredefinedlistItemsByRadius['error']
  > & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
export const postV1SurveysSurveyidQuestionShareofwalletGetPredefinedlistItemsByRadiusSuspenseQueryKey =
  (
    surveyId: PostV1SurveysSurveyidQuestionShareofwalletGetPredefinedlistItemsByRadiusPathParams['surveyId'],
    data: PostV1SurveysSurveyidQuestionShareofwalletGetPredefinedlistItemsByRadius['request'],
  ) =>
    [
      {
        url: '/v1/surveys/:surveyId/question/shareofwallet/get-predefinedlist-items-by-radius',
        params: { surveyId: surveyId },
      },
      ...(data ? [data] : []),
    ] as const
export type PostV1SurveysSurveyidQuestionShareofwalletGetPredefinedlistItemsByRadiusSuspenseQueryKey =
  ReturnType<
    typeof postV1SurveysSurveyidQuestionShareofwalletGetPredefinedlistItemsByRadiusSuspenseQueryKey
  >
export function postV1SurveysSurveyidQuestionShareofwalletGetPredefinedlistItemsByRadiusSuspenseQueryOptions(
  surveyId: PostV1SurveysSurveyidQuestionShareofwalletGetPredefinedlistItemsByRadiusPathParams['surveyId'],
  data: PostV1SurveysSurveyidQuestionShareofwalletGetPredefinedlistItemsByRadius['request'],
  headers?: PostV1SurveysSurveyidQuestionShareofwalletGetPredefinedlistItemsByRadius['headerParams'],
  options: PostV1SurveysSurveyidQuestionShareofwalletGetPredefinedlistItemsByRadius['client']['parameters'] = {},
) {
  const queryKey =
    postV1SurveysSurveyidQuestionShareofwalletGetPredefinedlistItemsByRadiusSuspenseQueryKey(
      surveyId,
      data,
    )
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        PostV1SurveysSurveyidQuestionShareofwalletGetPredefinedlistItemsByRadius['data'],
        PostV1SurveysSurveyidQuestionShareofwalletGetPredefinedlistItemsByRadius['error']
      >({
        method: 'post',
        url: `/v1/surveys/${surveyId}/question/shareofwallet/get-predefinedlist-items-by-radius`,
        data,
        headers: {
          'Content-Type': 'application/*+json',
          ...headers,
          ...options.headers,
        },
        ...options,
      })
      return res
    },
  })
}
/**
 * @link /v1/surveys/:surveyId/question/shareofwallet/get-predefinedlist-items-by-radius
 */
export function usePostV1SurveysSurveyidQuestionShareofwalletGetPredefinedlistItemsByRadiusSuspense<
  TData = PostV1SurveysSurveyidQuestionShareofwalletGetPredefinedlistItemsByRadius['response'],
  TQueryKey extends
    QueryKey = PostV1SurveysSurveyidQuestionShareofwalletGetPredefinedlistItemsByRadiusSuspenseQueryKey,
>(
  surveyId: PostV1SurveysSurveyidQuestionShareofwalletGetPredefinedlistItemsByRadiusPathParams['surveyId'],
  data: PostV1SurveysSurveyidQuestionShareofwalletGetPredefinedlistItemsByRadius['request'],
  headers?: PostV1SurveysSurveyidQuestionShareofwalletGetPredefinedlistItemsByRadius['headerParams'],
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        PostV1SurveysSurveyidQuestionShareofwalletGetPredefinedlistItemsByRadius['response'],
        PostV1SurveysSurveyidQuestionShareofwalletGetPredefinedlistItemsByRadius['error'],
        TData,
        TQueryKey
      >
    >
    client?: PostV1SurveysSurveyidQuestionShareofwalletGetPredefinedlistItemsByRadius['client']['parameters']
  } = {},
): UseSuspenseQueryResult<
  TData,
  PostV1SurveysSurveyidQuestionShareofwalletGetPredefinedlistItemsByRadius['error']
> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey =
    queryOptions?.queryKey ??
    postV1SurveysSurveyidQuestionShareofwalletGetPredefinedlistItemsByRadiusSuspenseQueryKey(
      surveyId,
      data,
    )
  const query = useSuspenseQuery({
    ...(postV1SurveysSurveyidQuestionShareofwalletGetPredefinedlistItemsByRadiusSuspenseQueryOptions(
      surveyId,
      data,
      headers,
      clientOptions,
    ) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<
    TData,
    PostV1SurveysSurveyidQuestionShareofwalletGetPredefinedlistItemsByRadius['error']
  > & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
