// @ts-nocheck
import client from '@/lib/client-fetch'
import { useQuery, queryOptions, useSuspenseQuery } from '@tanstack/react-query'
import type {
  PostV1SurveysSurveyidQuestionShareofwalletSearchClubReferenceMutationRequest,
  PostV1SurveysSurveyidQuestionShareofwalletSearchClubReferenceMutationResponse,
  PostV1SurveysSurveyidQuestionShareofwalletSearchClubReferencePathParams,
  PostV1SurveysSurveyidQuestionShareofwalletSearchClubReferenceHeaderParams,
  PostV1SurveysSurveyidQuestionShareofwalletSearchClubReference401,
  PostV1SurveysSurveyidQuestionShareofwalletSearchClubReference403,
} from '../models/PostV1SurveysSurveyidQuestionShareofwalletSearchClubReference'
import type {
  QueryObserverOptions,
  UseQueryResult,
  QueryKey,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query'

type PostV1SurveysSurveyidQuestionShareofwalletSearchClubReferenceClient =
  typeof client<
    PostV1SurveysSurveyidQuestionShareofwalletSearchClubReferenceMutationResponse,
    | PostV1SurveysSurveyidQuestionShareofwalletSearchClubReference401
    | PostV1SurveysSurveyidQuestionShareofwalletSearchClubReference403,
    PostV1SurveysSurveyidQuestionShareofwalletSearchClubReferenceMutationRequest
  >
type PostV1SurveysSurveyidQuestionShareofwalletSearchClubReference = {
  data: PostV1SurveysSurveyidQuestionShareofwalletSearchClubReferenceMutationResponse
  error:
    | PostV1SurveysSurveyidQuestionShareofwalletSearchClubReference401
    | PostV1SurveysSurveyidQuestionShareofwalletSearchClubReference403
  request: PostV1SurveysSurveyidQuestionShareofwalletSearchClubReferenceMutationRequest
  pathParams: PostV1SurveysSurveyidQuestionShareofwalletSearchClubReferencePathParams
  queryParams: never
  headerParams: PostV1SurveysSurveyidQuestionShareofwalletSearchClubReferenceHeaderParams
  response: Awaited<
    ReturnType<PostV1SurveysSurveyidQuestionShareofwalletSearchClubReferenceClient>
  >
  client: {
    parameters: Partial<
      Parameters<PostV1SurveysSurveyidQuestionShareofwalletSearchClubReferenceClient>[0]
    >
    return: Awaited<
      ReturnType<PostV1SurveysSurveyidQuestionShareofwalletSearchClubReferenceClient>
    >
  }
}
export const postV1SurveysSurveyidQuestionShareofwalletSearchClubReferenceQueryKey =
  (
    surveyId: PostV1SurveysSurveyidQuestionShareofwalletSearchClubReferencePathParams['surveyId'],
    data: PostV1SurveysSurveyidQuestionShareofwalletSearchClubReference['request'],
  ) =>
    [
      {
        url: '/v1/surveys/:surveyId/question/shareofwallet/search-club-reference',
        params: { surveyId: surveyId },
      },
      ...(data ? [data] : []),
    ] as const
export type PostV1SurveysSurveyidQuestionShareofwalletSearchClubReferenceQueryKey =
  ReturnType<
    typeof postV1SurveysSurveyidQuestionShareofwalletSearchClubReferenceQueryKey
  >
export function postV1SurveysSurveyidQuestionShareofwalletSearchClubReferenceQueryOptions(
  surveyId: PostV1SurveysSurveyidQuestionShareofwalletSearchClubReferencePathParams['surveyId'],
  data: PostV1SurveysSurveyidQuestionShareofwalletSearchClubReference['request'],
  headers?: PostV1SurveysSurveyidQuestionShareofwalletSearchClubReference['headerParams'],
  options: PostV1SurveysSurveyidQuestionShareofwalletSearchClubReference['client']['parameters'] = {},
) {
  const queryKey =
    postV1SurveysSurveyidQuestionShareofwalletSearchClubReferenceQueryKey(
      surveyId,
      data,
    )
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        PostV1SurveysSurveyidQuestionShareofwalletSearchClubReference['data'],
        PostV1SurveysSurveyidQuestionShareofwalletSearchClubReference['error']
      >({
        method: 'post',
        url: `/v1/surveys/${surveyId}/question/shareofwallet/search-club-reference`,
        data,
        headers: {
          'Content-Type': 'application/*+json',
          ...headers,
          ...options.headers,
        },
        ...options,
      })
      return res
    },
  })
}
/**
 * @link /v1/surveys/:surveyId/question/shareofwallet/search-club-reference
 */
export function usePostV1SurveysSurveyidQuestionShareofwalletSearchClubReference<
  TData = PostV1SurveysSurveyidQuestionShareofwalletSearchClubReference['response'],
  TQueryData = PostV1SurveysSurveyidQuestionShareofwalletSearchClubReference['response'],
  TQueryKey extends
    QueryKey = PostV1SurveysSurveyidQuestionShareofwalletSearchClubReferenceQueryKey,
>(
  surveyId: PostV1SurveysSurveyidQuestionShareofwalletSearchClubReferencePathParams['surveyId'],
  data: PostV1SurveysSurveyidQuestionShareofwalletSearchClubReference['request'],
  headers?: PostV1SurveysSurveyidQuestionShareofwalletSearchClubReference['headerParams'],
  options: {
    query?: Partial<
      QueryObserverOptions<
        PostV1SurveysSurveyidQuestionShareofwalletSearchClubReference['response'],
        PostV1SurveysSurveyidQuestionShareofwalletSearchClubReference['error'],
        TData,
        TQueryData,
        TQueryKey
      >
    >
    client?: PostV1SurveysSurveyidQuestionShareofwalletSearchClubReference['client']['parameters']
  } = {},
): UseQueryResult<
  TData,
  PostV1SurveysSurveyidQuestionShareofwalletSearchClubReference['error']
> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey =
    queryOptions?.queryKey ??
    postV1SurveysSurveyidQuestionShareofwalletSearchClubReferenceQueryKey(
      surveyId,
      data,
    )
  const query = useQuery({
    ...(postV1SurveysSurveyidQuestionShareofwalletSearchClubReferenceQueryOptions(
      surveyId,
      data,
      headers,
      clientOptions,
    ) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<
    TData,
    PostV1SurveysSurveyidQuestionShareofwalletSearchClubReference['error']
  > & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
export const postV1SurveysSurveyidQuestionShareofwalletSearchClubReferenceSuspenseQueryKey =
  (
    surveyId: PostV1SurveysSurveyidQuestionShareofwalletSearchClubReferencePathParams['surveyId'],
    data: PostV1SurveysSurveyidQuestionShareofwalletSearchClubReference['request'],
  ) =>
    [
      {
        url: '/v1/surveys/:surveyId/question/shareofwallet/search-club-reference',
        params: { surveyId: surveyId },
      },
      ...(data ? [data] : []),
    ] as const
export type PostV1SurveysSurveyidQuestionShareofwalletSearchClubReferenceSuspenseQueryKey =
  ReturnType<
    typeof postV1SurveysSurveyidQuestionShareofwalletSearchClubReferenceSuspenseQueryKey
  >
export function postV1SurveysSurveyidQuestionShareofwalletSearchClubReferenceSuspenseQueryOptions(
  surveyId: PostV1SurveysSurveyidQuestionShareofwalletSearchClubReferencePathParams['surveyId'],
  data: PostV1SurveysSurveyidQuestionShareofwalletSearchClubReference['request'],
  headers?: PostV1SurveysSurveyidQuestionShareofwalletSearchClubReference['headerParams'],
  options: PostV1SurveysSurveyidQuestionShareofwalletSearchClubReference['client']['parameters'] = {},
) {
  const queryKey =
    postV1SurveysSurveyidQuestionShareofwalletSearchClubReferenceSuspenseQueryKey(
      surveyId,
      data,
    )
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        PostV1SurveysSurveyidQuestionShareofwalletSearchClubReference['data'],
        PostV1SurveysSurveyidQuestionShareofwalletSearchClubReference['error']
      >({
        method: 'post',
        url: `/v1/surveys/${surveyId}/question/shareofwallet/search-club-reference`,
        data,
        headers: {
          'Content-Type': 'application/*+json',
          ...headers,
          ...options.headers,
        },
        ...options,
      })
      return res
    },
  })
}
/**
 * @link /v1/surveys/:surveyId/question/shareofwallet/search-club-reference
 */
export function usePostV1SurveysSurveyidQuestionShareofwalletSearchClubReferenceSuspense<
  TData = PostV1SurveysSurveyidQuestionShareofwalletSearchClubReference['response'],
  TQueryKey extends
    QueryKey = PostV1SurveysSurveyidQuestionShareofwalletSearchClubReferenceSuspenseQueryKey,
>(
  surveyId: PostV1SurveysSurveyidQuestionShareofwalletSearchClubReferencePathParams['surveyId'],
  data: PostV1SurveysSurveyidQuestionShareofwalletSearchClubReference['request'],
  headers?: PostV1SurveysSurveyidQuestionShareofwalletSearchClubReference['headerParams'],
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        PostV1SurveysSurveyidQuestionShareofwalletSearchClubReference['response'],
        PostV1SurveysSurveyidQuestionShareofwalletSearchClubReference['error'],
        TData,
        TQueryKey
      >
    >
    client?: PostV1SurveysSurveyidQuestionShareofwalletSearchClubReference['client']['parameters']
  } = {},
): UseSuspenseQueryResult<
  TData,
  PostV1SurveysSurveyidQuestionShareofwalletSearchClubReference['error']
> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey =
    queryOptions?.queryKey ??
    postV1SurveysSurveyidQuestionShareofwalletSearchClubReferenceSuspenseQueryKey(
      surveyId,
      data,
    )
  const query = useSuspenseQuery({
    ...(postV1SurveysSurveyidQuestionShareofwalletSearchClubReferenceSuspenseQueryOptions(
      surveyId,
      data,
      headers,
      clientOptions,
    ) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<
    TData,
    PostV1SurveysSurveyidQuestionShareofwalletSearchClubReference['error']
  > & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
