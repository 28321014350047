// @ts-nocheck
import client from '@/lib/client-fetch'
import { useQuery, queryOptions, useSuspenseQuery } from '@tanstack/react-query'
import type {
  PostV1GenericAccountmetricsResultsMaoSectionMutationRequest,
  PostV1GenericAccountmetricsResultsMaoSectionMutationResponse,
  PostV1GenericAccountmetricsResultsMaoSectionHeaderParams,
  PostV1GenericAccountmetricsResultsMaoSection401,
  PostV1GenericAccountmetricsResultsMaoSection403,
} from '../models/PostV1GenericAccountmetricsResultsMaoSection'
import type {
  QueryObserverOptions,
  UseQueryResult,
  QueryKey,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query'

type PostV1GenericAccountmetricsResultsMaoSectionClient = typeof client<
  PostV1GenericAccountmetricsResultsMaoSectionMutationResponse,
  | PostV1GenericAccountmetricsResultsMaoSection401
  | PostV1GenericAccountmetricsResultsMaoSection403,
  PostV1GenericAccountmetricsResultsMaoSectionMutationRequest
>
type PostV1GenericAccountmetricsResultsMaoSection = {
  data: PostV1GenericAccountmetricsResultsMaoSectionMutationResponse
  error:
    | PostV1GenericAccountmetricsResultsMaoSection401
    | PostV1GenericAccountmetricsResultsMaoSection403
  request: PostV1GenericAccountmetricsResultsMaoSectionMutationRequest
  pathParams: never
  queryParams: never
  headerParams: PostV1GenericAccountmetricsResultsMaoSectionHeaderParams
  response: Awaited<
    ReturnType<PostV1GenericAccountmetricsResultsMaoSectionClient>
  >
  client: {
    parameters: Partial<
      Parameters<PostV1GenericAccountmetricsResultsMaoSectionClient>[0]
    >
    return: Awaited<
      ReturnType<PostV1GenericAccountmetricsResultsMaoSectionClient>
    >
  }
}
export const postV1GenericAccountmetricsResultsMaoSectionQueryKey = (
  data?: PostV1GenericAccountmetricsResultsMaoSection['request'],
) =>
  [
    { url: '/v1/generic/accountmetrics/results/mao/section' },
    ...(data ? [data] : []),
  ] as const
export type PostV1GenericAccountmetricsResultsMaoSectionQueryKey = ReturnType<
  typeof postV1GenericAccountmetricsResultsMaoSectionQueryKey
>
export function postV1GenericAccountmetricsResultsMaoSectionQueryOptions(
  headers?: PostV1GenericAccountmetricsResultsMaoSection['headerParams'],
  data?: PostV1GenericAccountmetricsResultsMaoSection['request'],
  options: PostV1GenericAccountmetricsResultsMaoSection['client']['parameters'] = {},
) {
  const queryKey = postV1GenericAccountmetricsResultsMaoSectionQueryKey(data)
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        PostV1GenericAccountmetricsResultsMaoSection['data'],
        PostV1GenericAccountmetricsResultsMaoSection['error']
      >({
        method: 'post',
        url: `/v1/generic/accountmetrics/results/mao/section`,
        data,
        headers: {
          'Content-Type': 'application/*+json',
          ...headers,
          ...options.headers,
        },
        ...options,
      })
      return res
    },
  })
}
/**
 * @link /v1/generic/accountmetrics/results/mao/section
 */
export function usePostV1GenericAccountmetricsResultsMaoSection<
  TData = PostV1GenericAccountmetricsResultsMaoSection['response'],
  TQueryData = PostV1GenericAccountmetricsResultsMaoSection['response'],
  TQueryKey extends
    QueryKey = PostV1GenericAccountmetricsResultsMaoSectionQueryKey,
>(
  headers?: PostV1GenericAccountmetricsResultsMaoSection['headerParams'],
  data?: PostV1GenericAccountmetricsResultsMaoSection['request'],
  options: {
    query?: Partial<
      QueryObserverOptions<
        PostV1GenericAccountmetricsResultsMaoSection['response'],
        PostV1GenericAccountmetricsResultsMaoSection['error'],
        TData,
        TQueryData,
        TQueryKey
      >
    >
    client?: PostV1GenericAccountmetricsResultsMaoSection['client']['parameters']
  } = {},
): UseQueryResult<
  TData,
  PostV1GenericAccountmetricsResultsMaoSection['error']
> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey =
    queryOptions?.queryKey ??
    postV1GenericAccountmetricsResultsMaoSectionQueryKey(data)
  const query = useQuery({
    ...(postV1GenericAccountmetricsResultsMaoSectionQueryOptions(
      headers,
      data,
      clientOptions,
    ) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<
    TData,
    PostV1GenericAccountmetricsResultsMaoSection['error']
  > & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
export const postV1GenericAccountmetricsResultsMaoSectionSuspenseQueryKey = (
  data?: PostV1GenericAccountmetricsResultsMaoSection['request'],
) =>
  [
    { url: '/v1/generic/accountmetrics/results/mao/section' },
    ...(data ? [data] : []),
  ] as const
export type PostV1GenericAccountmetricsResultsMaoSectionSuspenseQueryKey =
  ReturnType<
    typeof postV1GenericAccountmetricsResultsMaoSectionSuspenseQueryKey
  >
export function postV1GenericAccountmetricsResultsMaoSectionSuspenseQueryOptions(
  headers?: PostV1GenericAccountmetricsResultsMaoSection['headerParams'],
  data?: PostV1GenericAccountmetricsResultsMaoSection['request'],
  options: PostV1GenericAccountmetricsResultsMaoSection['client']['parameters'] = {},
) {
  const queryKey =
    postV1GenericAccountmetricsResultsMaoSectionSuspenseQueryKey(data)
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        PostV1GenericAccountmetricsResultsMaoSection['data'],
        PostV1GenericAccountmetricsResultsMaoSection['error']
      >({
        method: 'post',
        url: `/v1/generic/accountmetrics/results/mao/section`,
        data,
        headers: {
          'Content-Type': 'application/*+json',
          ...headers,
          ...options.headers,
        },
        ...options,
      })
      return res
    },
  })
}
/**
 * @link /v1/generic/accountmetrics/results/mao/section
 */
export function usePostV1GenericAccountmetricsResultsMaoSectionSuspense<
  TData = PostV1GenericAccountmetricsResultsMaoSection['response'],
  TQueryKey extends
    QueryKey = PostV1GenericAccountmetricsResultsMaoSectionSuspenseQueryKey,
>(
  headers?: PostV1GenericAccountmetricsResultsMaoSection['headerParams'],
  data?: PostV1GenericAccountmetricsResultsMaoSection['request'],
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        PostV1GenericAccountmetricsResultsMaoSection['response'],
        PostV1GenericAccountmetricsResultsMaoSection['error'],
        TData,
        TQueryKey
      >
    >
    client?: PostV1GenericAccountmetricsResultsMaoSection['client']['parameters']
  } = {},
): UseSuspenseQueryResult<
  TData,
  PostV1GenericAccountmetricsResultsMaoSection['error']
> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey =
    queryOptions?.queryKey ??
    postV1GenericAccountmetricsResultsMaoSectionSuspenseQueryKey(data)
  const query = useSuspenseQuery({
    ...(postV1GenericAccountmetricsResultsMaoSectionSuspenseQueryOptions(
      headers,
      data,
      clientOptions,
    ) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<
    TData,
    PostV1GenericAccountmetricsResultsMaoSection['error']
  > & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
