// @ts-nocheck
import client from '@/lib/client-fetch'
import { useMutation } from '@tanstack/react-query'
import type {
  PostV1ProductProductidSettingsUpdatetrialsleftMutationResponse,
  PostV1ProductProductidSettingsUpdatetrialsleftPathParams,
  PostV1ProductProductidSettingsUpdatetrialsleftHeaderParams,
  PostV1ProductProductidSettingsUpdatetrialsleft400,
  PostV1ProductProductidSettingsUpdatetrialsleft401,
  PostV1ProductProductidSettingsUpdatetrialsleft403,
  PostV1ProductProductidSettingsUpdatetrialsleft404,
  PostV1ProductProductidSettingsUpdatetrialsleft500,
} from '../models/PostV1ProductProductidSettingsUpdatetrialsleft'
import type { UseMutationOptions } from '@tanstack/react-query'

type PostV1ProductProductidSettingsUpdatetrialsleftClient = typeof client<
  PostV1ProductProductidSettingsUpdatetrialsleftMutationResponse,
  | PostV1ProductProductidSettingsUpdatetrialsleft400
  | PostV1ProductProductidSettingsUpdatetrialsleft401
  | PostV1ProductProductidSettingsUpdatetrialsleft403
  | PostV1ProductProductidSettingsUpdatetrialsleft404
  | PostV1ProductProductidSettingsUpdatetrialsleft500,
  never
>
type PostV1ProductProductidSettingsUpdatetrialsleft = {
  data: PostV1ProductProductidSettingsUpdatetrialsleftMutationResponse
  error:
    | PostV1ProductProductidSettingsUpdatetrialsleft400
    | PostV1ProductProductidSettingsUpdatetrialsleft401
    | PostV1ProductProductidSettingsUpdatetrialsleft403
    | PostV1ProductProductidSettingsUpdatetrialsleft404
    | PostV1ProductProductidSettingsUpdatetrialsleft500
  request: never
  pathParams: PostV1ProductProductidSettingsUpdatetrialsleftPathParams
  queryParams: never
  headerParams: PostV1ProductProductidSettingsUpdatetrialsleftHeaderParams
  response: Awaited<
    ReturnType<PostV1ProductProductidSettingsUpdatetrialsleftClient>
  >
  client: {
    parameters: Partial<
      Parameters<PostV1ProductProductidSettingsUpdatetrialsleftClient>[0]
    >
    return: Awaited<
      ReturnType<PostV1ProductProductidSettingsUpdatetrialsleftClient>
    >
  }
}
/**
 * @link /v1/product/:productId/settings/updatetrialsleft
 */
export function usePostV1ProductProductidSettingsUpdatetrialsleft(
  options: {
    mutation?: UseMutationOptions<
      PostV1ProductProductidSettingsUpdatetrialsleft['response'],
      PostV1ProductProductidSettingsUpdatetrialsleft['error'],
      {
        productId: PostV1ProductProductidSettingsUpdatetrialsleftPathParams['productId']
        headers?: PostV1ProductProductidSettingsUpdatetrialsleft['headerParams']
      }
    >
    client?: PostV1ProductProductidSettingsUpdatetrialsleft['client']['parameters']
  } = {},
) {
  const { mutation: mutationOptions, client: clientOptions = {} } =
    options ?? {}
  return useMutation({
    mutationFn: async ({ productId, headers }) => {
      const res = await client<
        PostV1ProductProductidSettingsUpdatetrialsleft['data'],
        PostV1ProductProductidSettingsUpdatetrialsleft['error'],
        PostV1ProductProductidSettingsUpdatetrialsleft['request']
      >({
        method: 'post',
        url: `/v1/product/${productId}/settings/updatetrialsleft`,
        headers: { ...headers, ...clientOptions.headers },
        ...clientOptions,
      })
      return res
    },
    ...mutationOptions,
  })
}
