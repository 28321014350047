// @ts-nocheck
import client from '@/lib/client-fetch'
import { useQuery, queryOptions, useSuspenseQuery } from '@tanstack/react-query'
import type {
  PostV1GenericAccountmetricsResultsModuleListMutationRequest,
  PostV1GenericAccountmetricsResultsModuleListMutationResponse,
  PostV1GenericAccountmetricsResultsModuleListHeaderParams,
  PostV1GenericAccountmetricsResultsModuleList401,
  PostV1GenericAccountmetricsResultsModuleList403,
} from '../models/PostV1GenericAccountmetricsResultsModuleList'
import type {
  QueryObserverOptions,
  UseQueryResult,
  QueryKey,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query'

type PostV1GenericAccountmetricsResultsModuleListClient = typeof client<
  PostV1GenericAccountmetricsResultsModuleListMutationResponse,
  | PostV1GenericAccountmetricsResultsModuleList401
  | PostV1GenericAccountmetricsResultsModuleList403,
  PostV1GenericAccountmetricsResultsModuleListMutationRequest
>
type PostV1GenericAccountmetricsResultsModuleList = {
  data: PostV1GenericAccountmetricsResultsModuleListMutationResponse
  error:
    | PostV1GenericAccountmetricsResultsModuleList401
    | PostV1GenericAccountmetricsResultsModuleList403
  request: PostV1GenericAccountmetricsResultsModuleListMutationRequest
  pathParams: never
  queryParams: never
  headerParams: PostV1GenericAccountmetricsResultsModuleListHeaderParams
  response: Awaited<
    ReturnType<PostV1GenericAccountmetricsResultsModuleListClient>
  >
  client: {
    parameters: Partial<
      Parameters<PostV1GenericAccountmetricsResultsModuleListClient>[0]
    >
    return: Awaited<
      ReturnType<PostV1GenericAccountmetricsResultsModuleListClient>
    >
  }
}
export const postV1GenericAccountmetricsResultsModuleListQueryKey = (
  data: PostV1GenericAccountmetricsResultsModuleList['request'],
) =>
  [
    { url: '/v1/generic/accountmetrics/results/module/list' },
    ...(data ? [data] : []),
  ] as const
export type PostV1GenericAccountmetricsResultsModuleListQueryKey = ReturnType<
  typeof postV1GenericAccountmetricsResultsModuleListQueryKey
>
export function postV1GenericAccountmetricsResultsModuleListQueryOptions(
  data: PostV1GenericAccountmetricsResultsModuleList['request'],
  headers?: PostV1GenericAccountmetricsResultsModuleList['headerParams'],
  options: PostV1GenericAccountmetricsResultsModuleList['client']['parameters'] = {},
) {
  const queryKey = postV1GenericAccountmetricsResultsModuleListQueryKey(data)
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        PostV1GenericAccountmetricsResultsModuleList['data'],
        PostV1GenericAccountmetricsResultsModuleList['error']
      >({
        method: 'post',
        url: `/v1/generic/accountmetrics/results/module/list`,
        data,
        headers: {
          'Content-Type': 'application/*+json',
          ...headers,
          ...options.headers,
        },
        ...options,
      })
      return res
    },
  })
}
/**
 * @link /v1/generic/accountmetrics/results/module/list
 */
export function usePostV1GenericAccountmetricsResultsModuleList<
  TData = PostV1GenericAccountmetricsResultsModuleList['response'],
  TQueryData = PostV1GenericAccountmetricsResultsModuleList['response'],
  TQueryKey extends
    QueryKey = PostV1GenericAccountmetricsResultsModuleListQueryKey,
>(
  data: PostV1GenericAccountmetricsResultsModuleList['request'],
  headers?: PostV1GenericAccountmetricsResultsModuleList['headerParams'],
  options: {
    query?: Partial<
      QueryObserverOptions<
        PostV1GenericAccountmetricsResultsModuleList['response'],
        PostV1GenericAccountmetricsResultsModuleList['error'],
        TData,
        TQueryData,
        TQueryKey
      >
    >
    client?: PostV1GenericAccountmetricsResultsModuleList['client']['parameters']
  } = {},
): UseQueryResult<
  TData,
  PostV1GenericAccountmetricsResultsModuleList['error']
> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey =
    queryOptions?.queryKey ??
    postV1GenericAccountmetricsResultsModuleListQueryKey(data)
  const query = useQuery({
    ...(postV1GenericAccountmetricsResultsModuleListQueryOptions(
      data,
      headers,
      clientOptions,
    ) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<
    TData,
    PostV1GenericAccountmetricsResultsModuleList['error']
  > & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
export const postV1GenericAccountmetricsResultsModuleListSuspenseQueryKey = (
  data: PostV1GenericAccountmetricsResultsModuleList['request'],
) =>
  [
    { url: '/v1/generic/accountmetrics/results/module/list' },
    ...(data ? [data] : []),
  ] as const
export type PostV1GenericAccountmetricsResultsModuleListSuspenseQueryKey =
  ReturnType<
    typeof postV1GenericAccountmetricsResultsModuleListSuspenseQueryKey
  >
export function postV1GenericAccountmetricsResultsModuleListSuspenseQueryOptions(
  data: PostV1GenericAccountmetricsResultsModuleList['request'],
  headers?: PostV1GenericAccountmetricsResultsModuleList['headerParams'],
  options: PostV1GenericAccountmetricsResultsModuleList['client']['parameters'] = {},
) {
  const queryKey =
    postV1GenericAccountmetricsResultsModuleListSuspenseQueryKey(data)
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        PostV1GenericAccountmetricsResultsModuleList['data'],
        PostV1GenericAccountmetricsResultsModuleList['error']
      >({
        method: 'post',
        url: `/v1/generic/accountmetrics/results/module/list`,
        data,
        headers: {
          'Content-Type': 'application/*+json',
          ...headers,
          ...options.headers,
        },
        ...options,
      })
      return res
    },
  })
}
/**
 * @link /v1/generic/accountmetrics/results/module/list
 */
export function usePostV1GenericAccountmetricsResultsModuleListSuspense<
  TData = PostV1GenericAccountmetricsResultsModuleList['response'],
  TQueryKey extends
    QueryKey = PostV1GenericAccountmetricsResultsModuleListSuspenseQueryKey,
>(
  data: PostV1GenericAccountmetricsResultsModuleList['request'],
  headers?: PostV1GenericAccountmetricsResultsModuleList['headerParams'],
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        PostV1GenericAccountmetricsResultsModuleList['response'],
        PostV1GenericAccountmetricsResultsModuleList['error'],
        TData,
        TQueryKey
      >
    >
    client?: PostV1GenericAccountmetricsResultsModuleList['client']['parameters']
  } = {},
): UseSuspenseQueryResult<
  TData,
  PostV1GenericAccountmetricsResultsModuleList['error']
> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey =
    queryOptions?.queryKey ??
    postV1GenericAccountmetricsResultsModuleListSuspenseQueryKey(data)
  const query = useSuspenseQuery({
    ...(postV1GenericAccountmetricsResultsModuleListSuspenseQueryOptions(
      data,
      headers,
      clientOptions,
    ) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<
    TData,
    PostV1GenericAccountmetricsResultsModuleList['error']
  > & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
