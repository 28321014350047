// @ts-nocheck
import client from '@/lib/client-fetch'
import { useMutation } from '@tanstack/react-query'
import type {
  PutV1SurveysCustomSurveyidUpdateMutationRequest,
  PutV1SurveysCustomSurveyidUpdateMutationResponse,
  PutV1SurveysCustomSurveyidUpdatePathParams,
  PutV1SurveysCustomSurveyidUpdateHeaderParams,
  PutV1SurveysCustomSurveyidUpdate400,
  PutV1SurveysCustomSurveyidUpdate401,
  PutV1SurveysCustomSurveyidUpdate402,
  PutV1SurveysCustomSurveyidUpdate403,
  PutV1SurveysCustomSurveyidUpdate404,
  PutV1SurveysCustomSurveyidUpdate500,
} from '../models/PutV1SurveysCustomSurveyidUpdate'
import type { UseMutationOptions } from '@tanstack/react-query'

type PutV1SurveysCustomSurveyidUpdateClient = typeof client<
  PutV1SurveysCustomSurveyidUpdateMutationResponse,
  | PutV1SurveysCustomSurveyidUpdate400
  | PutV1SurveysCustomSurveyidUpdate401
  | PutV1SurveysCustomSurveyidUpdate402
  | PutV1SurveysCustomSurveyidUpdate403
  | PutV1SurveysCustomSurveyidUpdate404
  | PutV1SurveysCustomSurveyidUpdate500,
  PutV1SurveysCustomSurveyidUpdateMutationRequest
>
type PutV1SurveysCustomSurveyidUpdate = {
  data: PutV1SurveysCustomSurveyidUpdateMutationResponse
  error:
    | PutV1SurveysCustomSurveyidUpdate400
    | PutV1SurveysCustomSurveyidUpdate401
    | PutV1SurveysCustomSurveyidUpdate402
    | PutV1SurveysCustomSurveyidUpdate403
    | PutV1SurveysCustomSurveyidUpdate404
    | PutV1SurveysCustomSurveyidUpdate500
  request: PutV1SurveysCustomSurveyidUpdateMutationRequest
  pathParams: PutV1SurveysCustomSurveyidUpdatePathParams
  queryParams: never
  headerParams: PutV1SurveysCustomSurveyidUpdateHeaderParams
  response: Awaited<ReturnType<PutV1SurveysCustomSurveyidUpdateClient>>
  client: {
    parameters: Partial<Parameters<PutV1SurveysCustomSurveyidUpdateClient>[0]>
    return: Awaited<ReturnType<PutV1SurveysCustomSurveyidUpdateClient>>
  }
}
/**
 * @link /v1/surveys/custom/:surveyId/update
 */
export function usePutV1SurveysCustomSurveyidUpdate(
  options: {
    mutation?: UseMutationOptions<
      PutV1SurveysCustomSurveyidUpdate['response'],
      PutV1SurveysCustomSurveyidUpdate['error'],
      {
        surveyId: PutV1SurveysCustomSurveyidUpdatePathParams['surveyId']
        headers?: PutV1SurveysCustomSurveyidUpdate['headerParams']
        data: PutV1SurveysCustomSurveyidUpdate['request']
      }
    >
    client?: PutV1SurveysCustomSurveyidUpdate['client']['parameters']
  } = {},
) {
  const { mutation: mutationOptions, client: clientOptions = {} } =
    options ?? {}
  return useMutation({
    mutationFn: async ({ surveyId, data, headers }) => {
      const res = await client<
        PutV1SurveysCustomSurveyidUpdate['data'],
        PutV1SurveysCustomSurveyidUpdate['error'],
        PutV1SurveysCustomSurveyidUpdate['request']
      >({
        method: 'put',
        url: `/v1/surveys/custom/${surveyId}/update`,
        data,
        headers: {
          'Content-Type': 'application/*+json',
          ...headers,
          ...clientOptions.headers,
        },
        ...clientOptions,
      })
      return res
    },
    ...mutationOptions,
  })
}
