// @ts-nocheck
import client from '@/lib/client-fetch'
import { useQuery, queryOptions, useSuspenseQuery } from '@tanstack/react-query'
import type {
  PostV1SurveysSurveyidQuestionShareofwalletCustomselectionMutationRequest,
  PostV1SurveysSurveyidQuestionShareofwalletCustomselectionMutationResponse,
  PostV1SurveysSurveyidQuestionShareofwalletCustomselectionPathParams,
  PostV1SurveysSurveyidQuestionShareofwalletCustomselectionHeaderParams,
  PostV1SurveysSurveyidQuestionShareofwalletCustomselection401,
  PostV1SurveysSurveyidQuestionShareofwalletCustomselection403,
} from '../models/PostV1SurveysSurveyidQuestionShareofwalletCustomselection'
import type {
  QueryObserverOptions,
  UseQueryResult,
  QueryKey,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query'

type PostV1SurveysSurveyidQuestionShareofwalletCustomselectionClient =
  typeof client<
    PostV1SurveysSurveyidQuestionShareofwalletCustomselectionMutationResponse,
    | PostV1SurveysSurveyidQuestionShareofwalletCustomselection401
    | PostV1SurveysSurveyidQuestionShareofwalletCustomselection403,
    PostV1SurveysSurveyidQuestionShareofwalletCustomselectionMutationRequest
  >
type PostV1SurveysSurveyidQuestionShareofwalletCustomselection = {
  data: PostV1SurveysSurveyidQuestionShareofwalletCustomselectionMutationResponse
  error:
    | PostV1SurveysSurveyidQuestionShareofwalletCustomselection401
    | PostV1SurveysSurveyidQuestionShareofwalletCustomselection403
  request: PostV1SurveysSurveyidQuestionShareofwalletCustomselectionMutationRequest
  pathParams: PostV1SurveysSurveyidQuestionShareofwalletCustomselectionPathParams
  queryParams: never
  headerParams: PostV1SurveysSurveyidQuestionShareofwalletCustomselectionHeaderParams
  response: Awaited<
    ReturnType<PostV1SurveysSurveyidQuestionShareofwalletCustomselectionClient>
  >
  client: {
    parameters: Partial<
      Parameters<PostV1SurveysSurveyidQuestionShareofwalletCustomselectionClient>[0]
    >
    return: Awaited<
      ReturnType<PostV1SurveysSurveyidQuestionShareofwalletCustomselectionClient>
    >
  }
}
export const postV1SurveysSurveyidQuestionShareofwalletCustomselectionQueryKey =
  (
    surveyId: PostV1SurveysSurveyidQuestionShareofwalletCustomselectionPathParams['surveyId'],
    data: PostV1SurveysSurveyidQuestionShareofwalletCustomselection['request'],
  ) =>
    [
      {
        url: '/v1/surveys/:surveyId/question/shareofwallet/customselection',
        params: { surveyId: surveyId },
      },
      ...(data ? [data] : []),
    ] as const
export type PostV1SurveysSurveyidQuestionShareofwalletCustomselectionQueryKey =
  ReturnType<
    typeof postV1SurveysSurveyidQuestionShareofwalletCustomselectionQueryKey
  >
export function postV1SurveysSurveyidQuestionShareofwalletCustomselectionQueryOptions(
  surveyId: PostV1SurveysSurveyidQuestionShareofwalletCustomselectionPathParams['surveyId'],
  data: PostV1SurveysSurveyidQuestionShareofwalletCustomselection['request'],
  headers?: PostV1SurveysSurveyidQuestionShareofwalletCustomselection['headerParams'],
  options: PostV1SurveysSurveyidQuestionShareofwalletCustomselection['client']['parameters'] = {},
) {
  const queryKey =
    postV1SurveysSurveyidQuestionShareofwalletCustomselectionQueryKey(
      surveyId,
      data,
    )
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        PostV1SurveysSurveyidQuestionShareofwalletCustomselection['data'],
        PostV1SurveysSurveyidQuestionShareofwalletCustomselection['error']
      >({
        method: 'post',
        url: `/v1/surveys/${surveyId}/question/shareofwallet/customselection`,
        data,
        headers: {
          'Content-Type': 'application/*+json',
          ...headers,
          ...options.headers,
        },
        ...options,
      })
      return res
    },
  })
}
/**
 * @link /v1/surveys/:surveyId/question/shareofwallet/customselection
 */
export function usePostV1SurveysSurveyidQuestionShareofwalletCustomselection<
  TData = PostV1SurveysSurveyidQuestionShareofwalletCustomselection['response'],
  TQueryData = PostV1SurveysSurveyidQuestionShareofwalletCustomselection['response'],
  TQueryKey extends
    QueryKey = PostV1SurveysSurveyidQuestionShareofwalletCustomselectionQueryKey,
>(
  surveyId: PostV1SurveysSurveyidQuestionShareofwalletCustomselectionPathParams['surveyId'],
  data: PostV1SurveysSurveyidQuestionShareofwalletCustomselection['request'],
  headers?: PostV1SurveysSurveyidQuestionShareofwalletCustomselection['headerParams'],
  options: {
    query?: Partial<
      QueryObserverOptions<
        PostV1SurveysSurveyidQuestionShareofwalletCustomselection['response'],
        PostV1SurveysSurveyidQuestionShareofwalletCustomselection['error'],
        TData,
        TQueryData,
        TQueryKey
      >
    >
    client?: PostV1SurveysSurveyidQuestionShareofwalletCustomselection['client']['parameters']
  } = {},
): UseQueryResult<
  TData,
  PostV1SurveysSurveyidQuestionShareofwalletCustomselection['error']
> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey =
    queryOptions?.queryKey ??
    postV1SurveysSurveyidQuestionShareofwalletCustomselectionQueryKey(
      surveyId,
      data,
    )
  const query = useQuery({
    ...(postV1SurveysSurveyidQuestionShareofwalletCustomselectionQueryOptions(
      surveyId,
      data,
      headers,
      clientOptions,
    ) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<
    TData,
    PostV1SurveysSurveyidQuestionShareofwalletCustomselection['error']
  > & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
export const postV1SurveysSurveyidQuestionShareofwalletCustomselectionSuspenseQueryKey =
  (
    surveyId: PostV1SurveysSurveyidQuestionShareofwalletCustomselectionPathParams['surveyId'],
    data: PostV1SurveysSurveyidQuestionShareofwalletCustomselection['request'],
  ) =>
    [
      {
        url: '/v1/surveys/:surveyId/question/shareofwallet/customselection',
        params: { surveyId: surveyId },
      },
      ...(data ? [data] : []),
    ] as const
export type PostV1SurveysSurveyidQuestionShareofwalletCustomselectionSuspenseQueryKey =
  ReturnType<
    typeof postV1SurveysSurveyidQuestionShareofwalletCustomselectionSuspenseQueryKey
  >
export function postV1SurveysSurveyidQuestionShareofwalletCustomselectionSuspenseQueryOptions(
  surveyId: PostV1SurveysSurveyidQuestionShareofwalletCustomselectionPathParams['surveyId'],
  data: PostV1SurveysSurveyidQuestionShareofwalletCustomselection['request'],
  headers?: PostV1SurveysSurveyidQuestionShareofwalletCustomselection['headerParams'],
  options: PostV1SurveysSurveyidQuestionShareofwalletCustomselection['client']['parameters'] = {},
) {
  const queryKey =
    postV1SurveysSurveyidQuestionShareofwalletCustomselectionSuspenseQueryKey(
      surveyId,
      data,
    )
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        PostV1SurveysSurveyidQuestionShareofwalletCustomselection['data'],
        PostV1SurveysSurveyidQuestionShareofwalletCustomselection['error']
      >({
        method: 'post',
        url: `/v1/surveys/${surveyId}/question/shareofwallet/customselection`,
        data,
        headers: {
          'Content-Type': 'application/*+json',
          ...headers,
          ...options.headers,
        },
        ...options,
      })
      return res
    },
  })
}
/**
 * @link /v1/surveys/:surveyId/question/shareofwallet/customselection
 */
export function usePostV1SurveysSurveyidQuestionShareofwalletCustomselectionSuspense<
  TData = PostV1SurveysSurveyidQuestionShareofwalletCustomselection['response'],
  TQueryKey extends
    QueryKey = PostV1SurveysSurveyidQuestionShareofwalletCustomselectionSuspenseQueryKey,
>(
  surveyId: PostV1SurveysSurveyidQuestionShareofwalletCustomselectionPathParams['surveyId'],
  data: PostV1SurveysSurveyidQuestionShareofwalletCustomselection['request'],
  headers?: PostV1SurveysSurveyidQuestionShareofwalletCustomselection['headerParams'],
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        PostV1SurveysSurveyidQuestionShareofwalletCustomselection['response'],
        PostV1SurveysSurveyidQuestionShareofwalletCustomselection['error'],
        TData,
        TQueryKey
      >
    >
    client?: PostV1SurveysSurveyidQuestionShareofwalletCustomselection['client']['parameters']
  } = {},
): UseSuspenseQueryResult<
  TData,
  PostV1SurveysSurveyidQuestionShareofwalletCustomselection['error']
> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey =
    queryOptions?.queryKey ??
    postV1SurveysSurveyidQuestionShareofwalletCustomselectionSuspenseQueryKey(
      surveyId,
      data,
    )
  const query = useSuspenseQuery({
    ...(postV1SurveysSurveyidQuestionShareofwalletCustomselectionSuspenseQueryOptions(
      surveyId,
      data,
      headers,
      clientOptions,
    ) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<
    TData,
    PostV1SurveysSurveyidQuestionShareofwalletCustomselection['error']
  > & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
